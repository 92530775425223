<template>
  <b-row>
    <b-col sm="12" xl="8">
      <c-card title="Tambah Baru">
        <template slot="action">
          <c-button :to="{name:'itemcategories'}" variant="outline-primary">Kembali</c-button>
          <c-button :loading="isloading" @click="$refs.form.onSubmit()" type="submit">Simpan</c-button>
        </template>
        <c-form ref="form" @submit="confirmSubmit">
          <form-select-all label="Kategori Induk" v-model="parent" placeholder="Kosongkan jika kategori induk" endpoint="v1/item_categories/select2"></form-select-all>
          <form-input label="Kode" v-model="formData.code" :rules="{required: true}"></form-input>
          <form-input label="Nama" v-model="formData.name" :rules="{required: true}"></form-input>
          <form-textarea label="Keterangan" v-model="formData.note"></form-textarea>
          <!-- <form-checkbox v-model="formData.is_active" label="Aktif"></form-checkbox> -->
          <button type="submit" hidden></button>
        </c-form>
      </c-card>
    </b-col>
  </b-row>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import FormInput from '@/utils/components/FormInput.vue'
import CForm from '@/utils/components/CForm.vue'
import CButton from '@/utils/components/CButton.vue'
import FormSelectAll from '@/utils/components/FormSelectAll.vue'
import FormTextarea from '@/utils/components/FormTextarea.vue'

export default {
  components: { CCard, FormInput, CForm, CButton, FormSelectAll, FormTextarea },
  data(){
    const formData = {
      code:'',
      name:'',
      note:'',
      is_active: true
    }
    return {
      formData,
      parent:null,
      isloading: false
    }
  },
  methods:{
    confirmSubmit(){
      this.confirmation(`submitForm`)
    },
    submitForm(){
      const vm = this
      vm.isloading = true
      const formData = JSON.parse(JSON.stringify(vm.formData))
      if (vm.parent) {
        formData.id_parent = vm.parent.value
      }
      this.$http.post(`v1/item_categories/create`,formData).then(() => {
        vm.notify(`Data Berhasil Disimpan!`)
        vm.$router.push({name:'itemcategories'})
        vm.isloading = false
      }).catch((error) => {
        vm.isloading = false
        vm.handleError(error)
      })
    }
  }
}
</script>

<style>

</style>